 
<template>
  <AppMaskField
    label="CNPJ"
    mask="##.###.###/####-##"
    v-model="cnpj"
    @click:prepend-inner="search()"
    :loading="loading"
  >
    <template v-slot:append-inner>
      <v-icon
        v-if="showSearch"
        color="primary"
        @click.stop="search()"
        icon="mdi:mdi-search-web"
      />
    </template>
  </AppMaskField>
</template>
<script setup>
import axios from "axios";

import { debounce } from "lodash";

const props = defineProps({
  modelValue: {},

  showSearch: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["company", "update:modelValue"]);

const loading = ref(false);

const cnpj = ref(null);

watch(
  () => props.modelValue,
  (newValue) => (cnpj.value = newValue)
);

watch(
  () => cnpj.value,
  (newValue) => emit("update:modelValue", newValue)
);

onMounted(() => (cnpj.value = props.modelValue));

const search = async () => {
  if (cnpj.value && cnpj.value.length) {
    loading.value = true;

    cnpj.value = cnpj.value.replace(/[^\d]+/g, "");

    await axios
      .get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj.value}`)
      .then((response) => {
        emitCompany(response.data);
        loading.value = false;
      })
      .catch((error) => (loading.value = false));

  }
    
};

const emitCompany = (data) => {
  emit("company", {
    name: data.razao_social ?? null,
    cnpj: data.cnpj ?? null,
    phone: data.ddd_telefone_1 ?? null,
    email: data.email ?? null,
    main_address: {
      type: "mainAddress",
      postal_code: data.cep ?? null,
      city_code: data.codigo_municipio_ibge ?? null,
      state: data.uf ?? null,
      city: data.municipio ?? null,
      address: data.logradouro ?? null,
      number: data.numero ?? null,
      complement: data.complemento ?? null,
      district: data.bairro ?? null,
    },
  });
};
</script>